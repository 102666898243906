import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';
import logo from './logo.png';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography } from '@material-ui/core';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import { DeviceSelector } from './DeviceSelector/DeviceSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 300,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    joinButton: {
      margin: '1em',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      marginTop: '25px',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    img: {
      width: '110px',
      height: '60px',
    },
    copyButton: {
      fontSize: '08px',
    }
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { URLRoomName } = useParams();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect } = useVideoContext();
  const roomState = useRoomState();
  const [open, setOpen] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(true);
  const [passOk, setPassOk] = React.useState(false);
  const [roomAuthenticated, setRoomAuthenticated] = React.useState(false);
  const [password, setPassword] = useState<string>('');
  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  let [time, setTime] = useState<number>(4260);
  const [countTime, setCountTime] = useState<string>('4260');
  useEffect(() => {
    if (URLRoomName && URLRoomName.slice(0,2) === 'hi') {
      setRoomName(URLRoomName);
      setPassOk(true);
      setPassword('Hiconnect99');
      setRoomAuthenticated(true);
    }
  }, [URLRoomName]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

   let copyToClipBoard = () => {
    const texto = window.location.href;

    let inputTest = document.createElement("input");

    inputTest.value = texto;
    document.body.appendChild(inputTest);
    inputTest.select();
    document.execCommand('copy');
    document.body.removeChild(inputTest);
  };

  const handleSubmit = () => {
    window.location.href = `https://meet.jit.si/plataforma-hi-connect-${roomName}`;
    // event.preventDefault();
    // if (!window.location.origin.includes('twil.io')) {
    //   window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    // }
    //
    // startCountdown();
    // getToken(name, roomName).then(token => connect(token));
  };

  let min = 0;

  function startCountdown(){

    if((time - 1) > 0){

      min = parseInt(String(time / 60));

      if(min < 10){
        min = parseInt("0"+ String(min));
      }

      setCountTime(String(min));

      setTimeout(() => {
        startCountdown()
      }, 1000);

      setTime(time--);

    } else {
      setOpen(true);
    }
  }

  const handleResetTime = () => {
    setOpen(false);
  };

  if (open) {
    setTimeout(() => {
      window.open('/', '_self')
    }, 60000);
  }

  const handleClose = () => {
      window.open('/', '_self')
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };



  useEffect(() => {
    let room = Math.random().toString(36).substring(2, 15);
    if(URLRoomName && URLRoomName.slice(0,2) === 'hi'){
      setRoomName(URLRoomName);
      setPassOk(true);
      setPassword('Hiconnect99');
      setRoomAuthenticated(true);
    } else {
      if (password === 'Hiconnect99' || password === 'Equality99' || password === 'Saudehi99') {
        setPassOk(true)
        setRoomName('hi' + room)
      } else {
        setPassOk(false)
        setRoomName('')
      }
    }
  }, [password, URLRoomName]);

  async function passVerify(e: React.ChangeEvent<HTMLInputElement>) {
    await setPassword(e.target.value);
  }

  // @ts-ignore
  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        {roomState === 'disconnected' ? (
          <form className={classes.form} onSubmit={handleSubmit}>
            <img className={classes.img} src={logo} alt="logo"/>
            {window.location.search.includes('customIdentity=true') || !user?.displayName ? (
              <TextField
                id="menu-name"
                label="Digite seu nome:"
                className={classes.textField}
                value={name}
                onChange={handleNameChange}
                margin="dense"
                helperText="Preencha seu nome"
              />
            ) : (
              <Typography className={classes.displayName} variant="body1">
                {user.displayName}
              </Typography>
            )}
            { !roomAuthenticated && !passOk ? (<TextField
              id="menu-room"
              label="Senha"
              type="password"
              className={classes.textField}
              value={password}
              onChange={passVerify}
              margin="dense"
              helperText="Confira a senha abaixo do botão de criar a sala na sua plataforma"
            />) : null }

            {!roomName ? (
              <div>
                <Dialog
                  open={openInfo}
                  onClose={handleCloseInfo}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Bem vindo a Plataforma Hi! Connect "}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Para prosseguir siga os passos: <br/>
                      - Preencha seu nome<br/>
                      - Preencha com sua senha para poder gerar o Código da Sala<br/>
                      - A senha pode ser encontrada na sua plataforma, logo abaixo do botão de criar sala<br/>
                      - Clique em entrar<br/>
                      <br/>
                      * A sala só é criada após a realização dessas três etapas;<br/>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseInfo} color="primary" autoFocus>
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
                <TextField
                  disabled
                  id="menu-room"
                  label="Código da sala"
                  className={classes.textField}
                  value={roomName}
                  margin="dense"
                  helperText="O código só será gerado com a senha correta"
                />
              </div>
            ) : (
              <div>
                <h3>{roomName}</h3>
                <Dialog
                  open={openInfo}
                  onClose={handleCloseInfo}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Bem vindo a Plataforma Hi! Connect "}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Para prosseguir siga os passos: <br/>
                      - Preencha seu nome<br/>
                      - Clique em entrar<br/>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseInfo} color="primary" autoFocus>
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            )}
            <Button
              className={classes.joinButton}
              onClick={handleSubmit}
              type="button"
              color="primary"
              variant="contained"
              disabled={isConnecting || !name  || isFetching || !roomName || !passOk}
            >
              Entrar
            </Button>
            {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
          </form>
        ) : (
          <div className={classes.form}>
            <img className={classes.img} src={logo} alt=""/>
            <p>Tempo restante: {countTime} minutos</p>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Tempo Esgotado!"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  O tempo de duração da sala esgotou, escolha a opção abaixo.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Fechar Sala
                </Button>
                <Button onClick={handleResetTime} color="primary" autoFocus>
                  Voltar à sala
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
        <div className={classes.rightButtonContainer}>
          {roomState === 'disconnected' ? (
            <p></p>
          ) : (
            <Button
              className={classes.copyButton}
              color="primary"
              variant="contained"
              onClick={() => copyToClipBoard()}>
              <FileCopyIcon/> Copiar Link
            </Button>
          )}
          <FlipCameraButton />
          <DeviceSelector />
          <ToggleFullscreenButton />
          <Menu />
        </div>
      </Toolbar>
    </AppBar>
  );
}
